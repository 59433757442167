<template>
  <a-modal
    title="客户"
    :visible="visible"
    @ok="whenSubmit"
    @cancel="$emit('cancel')"
    :destroyOnClose="true"
    width="650px"
    :okText="'确定'"
  >
    <a-form-model
      class="formInfo"
      ref="addCusForm"
      layout="horizontal"
      :rules="rules"
      :model="addCusForm"
      :label-col="{ span: 7 }"
      :wrapper-col="{ span: 17 }"
      labelAlign="right"
    >
      <a-form-model-item label="客户名称" prop="name">
        <a-input
          type="text"
          placeholder="在此输入客户名称"
          v-model="addCusForm.name"
          autocomplete="off"
          :disabled="readonly"
          style="width: 330px"
        />
      </a-form-model-item>
      <a-form-model-item label="联系人" prop="contactPerson">
        <a-input
          type="text"
          placeholder="在此输入联系人"
          v-model="addCusForm.contactPerson"
          autocomplete="off"
          :disabled="readonly"
          style="width: 330px"
        />
      </a-form-model-item>
      <a-form-model-item label="联系方式" prop="contactInfo">
        <a-input
          type="text"
          placeholder="在此输入联系方式"
          v-model="addCusForm.contactInfo"
          autocomplete="off"
          :disabled="isDetail"
          style="width: 330px"
        />
      </a-form-model-item>

      <a-form-model-item label="客户地址" prop="address">
        <a-select
          v-model="addCusForm.address.provinceId"
          style="width: 110px"
          @change="handleProvinceChange(addCusForm.address.provinceId, '')"
          placeholder="请选择省"
        >
          <!-- <a-select-option value="Yiminghe">
          yiminghe
          </a-select-option> -->
          <a-select-option
            v-for="province in provinceData"
            :key="province.no"
            :value="province.id"
          >
            {{ province.regionName }}
          </a-select-option>
        </a-select>
        <a-select
          v-model="addCusForm.address.cityId"
          style="width: 110px"
          @change="handleCityChange(addCusForm.address.cityId, '')"
          placeholder="请选择市"
        >
          <a-select-option
            v-for="city in cityData"
            :key="city.no"
            :value="city.id"
          >
            {{ city.regionName }}
          </a-select-option>
        </a-select>
        <a-select
          v-model="addCusForm.address.areaId"
          style="width: 110px"
          @change="handleAreaChange()"
          placeholder="请选择区"
        >
          <a-select-option
            v-for="area in areaData"
            :key="area.no"
            :value="area.id"
          >
            {{ area.regionName }}
          </a-select-option>
        </a-select>
        <a-input
          type="text"
          placeholder="请输入详细地址"
          v-model="addCusForm.address.addressDetail"
          autocomplete="off"
          style="width: 330px"
        />
      </a-form-model-item>
      <a-form-model-item label="创建时间" prop="creationTime" v-show="!isAdd">
        <a-input
          type="text"
          v-model="magFilteredTime"
          autocomplete="off"
          :disabled="true"
          style="width: 330px"
        />
      </a-form-model-item>
      <a-form-model-item label="启用状态" prop="enableStatus" v-show="!isAdd">
        <a-select
          v-model="addCusForm.enableStatus"
          style="width: 330px"
          :disabled="isDetail"
        >
          <a-select-option
            v-for="item in this.$parent.arrEnableStatus"
            :key="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { isTelephone } from "@/utils/tools";
import { createCustomer, updateCustomer } from "/src/api/customer.js";
import { getRegionListByParentNo } from "@/api/customer.js";
//import SelectRegions from "./SelectRegions.vue";
export default {
  // components: {
  //   SelectRegions,
  // },
  name: "AddCustomer",
  props: {
    visible: { type: Boolean, default: () => false },
    readonly: { type: Boolean, default: () => false },
    form: { type: Object, default: () => {} },
    isEdit: { type: Boolean, default: () => false },
    isAdd: { type: Boolean, default: () => false },
    isDetail: { type: Boolean, default: () => false },
  },
  created() {
    this.getProvinces();
    console.log("要看的addCusForm1", this.addCusForm);
  },
  computed: {
    magFilteredTime: {
      get() {
        return this.filterTime(this.addCusForm.creationTime);
      },
      set(val) {
        this.msg = val;
      },
    },
  },
  data() {
    let isPhone = (rule, value, callback) => {
      isTelephone(rule, value, callback);
    };
    let namevalidator = (rule, value, cbfn) => {
      var _this = this;
      var name = _this.addCusForm.name;
      if (!value) cbfn("客户名称不能为空");
      if (new TextEncoder().encode(name).length > 150)
        cbfn("客户名称不能超过50个字!");
      cbfn();
    };
    let contactvalidator = (rule, value, cbfn) => {
      var _this = this;
      var contactPerson = _this.addCusForm.contactPerson;
      if (!value) cbfn("联系人不能为空");
      if (new TextEncoder().encode(contactPerson).length > 60) {
        var result = new TextEncoder().encode(contactPerson).length;
        cbfn("联系人不能超过20个字!");
      }
      cbfn();
    };
    return {
      addCusForm: {
        name: "",
        contactPerson: "",
        contactInfo: "",
        creationTime: "",
        enableStatus: 0,
        addressDetail: "",
        address: {
          areaId: undefined,
          cityId: undefined,
          provinceId: undefined,
          addressDetail: undefined,
        },
        provinceData: [],
        cityData: [],
        areaData: [],
      },
      provinceData: [],
      cityData: [],
      areaData: [],
      /** 表单验证规则 */
      rules: {
        name: [{ required: true, validator: namevalidator, trigger: "blur" }],
        contactPerson: [
          { required: true, validator: contactvalidator, trigger: "blur" },
        ],
        contactInfo: [{ validator: isPhone, trigger: "blur", required: true }],
        // address: {
        //   provinceId: [
        //     { required: true, trigger: "blur" },//, message: "所在省/直辖市不能为空"
        //   ],
        //   cityId: [
        //     { required: true, trigger: "blur" },//, message: "所在市不能为空"
        //   ],
        //   areaId: [
        //     { required: true, trigger: "blur" },//, message: "所在区县不能为空"
        //   ],
        //   // addressDetail: [
        //   //   { required: true, message: "详细地址不能为空", trigger: "blur" },
        //   // ],
        //   message: "请完整输入所在地信息",
        //   trigger: "blur"
        // },
      },
    };
  },
  methods: {
    filterTime(val) {
      if (val) {
        let dt = val.substring(0, 19).replace("T", " ");
        return dt;
      } else {
        return "";
      }
    },
    /** 保存事件 */
    whenSubmit() {
      if (this.readonly) return this.$emit("cancel");
      this.$refs.addCusForm.validate((valid) => {
        if (valid) {
          if (!this.addCusForm.id) {
            createCustomer(this.addCusForm)
              .then(() => {
                this.$emit("submited");
                this.$emit("getData");
                this.$message.success("保存成功");
              })
              .catch(() => {
                this.$message.error("保存失败");
              });
          } else {
            console.log(this.addCusForm);
            updateCustomer(this.addCusForm)
              .then(() => {
                this.$emit("submited");
                this.$emit("handleTableChange", this.$parent.pagination);
                this.$message.success("保存成功");
              })
              .catch(() => {
                this.$message.error("保存失败");
              });
          }
        }
      });
    },

    handleProvinceChange(parentId, parentNo) {
      if (!parentNo) {
        parentNo = this.provinceData.find((aa) => aa.id == parentId).no;
      }
      getRegionListByParentNo(parentNo).then((res) => {
        this.cityData = res;
      });
      this.addCusForm.address.cityId = null;
      this.addCusForm.address.areaId = null;
    },
    handleCityChange(parentId, parentNo) {
      if (!parentNo) {
        parentNo = this.cityData.find((aa) => aa.id == parentId).no;
      }
      getRegionListByParentNo(parentNo).then((res) => {
        this.areaData = res;
      });
      this.addCusForm.address.areaId = null;
    },
    handleAreaChange() {
      //应该通过数据绑定的方式而不是这样赋值
      //this.$parent.addCusForm.address = this.address;
      console.log("要看的addCusForm1", this.addCusForm);
    },
    getProvinces() {
      var _this = this;
      getRegionListByParentNo("0").then((res) => {
        _this.provinceData = res;
        console.log("provinceData", this.provinceData);
      });
    },
  },
  watch: {
    form: {
      handler() {
        let _this = this;
        console.log("addCusForm有没有值1", _this.form);
        _this.addCusForm = _this.form;
        if (_this.addCusForm.address.provinceId != null) {
          let parentNo = _this.provinceData.find(
            (aa) => aa.id == _this.addCusForm.address.provinceId
          ).no;
          getRegionListByParentNo(parentNo).then((res) => {
            _this.cityData = res;
            if (_this.addCusForm.address.cityId != null) {
              parentNo = _this.cityData.find(
                (aa) => aa.id == _this.addCusForm.address.cityId
              ).no;
              getRegionListByParentNo(parentNo).then((res) => {
                _this.areaData = res;
              });
            }
          });
        }
        console.log("addCusForm有没有值2", _this.addCusForm.address);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox {
  margin-left: 10px;
}
</style>
