import {
  postRequest,
  deleteRequest,
  getRequest,
  updateRequest,
} from "@/utils/http";

/**新增客户 */
export const createCustomer = (params) =>
  postRequest(`/sale/customer/create`, params);

/**编辑客户 */
export const updateCustomer = (params) =>
  updateRequest(`/sale/customer/update`, params);

/**通过id拿到一条数据 */
export const getByIdCustomer = (id) =>
  getRequest(`/sale/customer/getById/${id}`);

/**获取客户列表 */
export const getListCustomer = (params) =>
  getRequest(`/sale/customer/getList`, params);

/**导入客户 */
export const importCustomer = (params) =>
  postRequest(`/sale/customer/createByImport`, params);

/**获取所有客户启用状态 */
export const getAllEnableStatusEnum = (params) =>
  getRequest(`/sale/customer/getAllEnableStatusEnum`, params);

/**通过ParentNo获取所有子级Region */
export const getRegionListByParentNo = (parentNo) =>
  getRequest(`/sale/customer/getRegionListByParentNo/${parentNo}`);

/**导入联系人 */
export const createByImport = (params) =>
  postRequest(`/sale/customer/createByImport`, params);

/**解析客户导入文件 */
export const parseImportCustomer = (params) =>
  postRequest(`/sale/customer/parseImportCustomer`, params);

/**下载客户导入模板 */
export const getCustomerExcel = () =>
  getRequest(`/sale/customer/getCustomerExcel`);

/**新增联系人 */
export const createContactPerson = (params) =>
  postRequest(`/sale/contactPerson/create`, params);

/**编辑联系人 */
export const updateContactPerson = (params) =>
  updateRequest(`/sale/contactPerson/update`, params);

/**根据id拿到一条数据 */
export const getByIdContactPerson = (id) =>
  getRequest(`/sale/contactPerson/getById${id}`);

/**获取联系人列表 */
export const getListContactPerson = (params) =>
  getRequest(`/sale/contactPerson/getList`, params);

/**导入联系人 */
export const importContactPerson = (params) =>
  postRequest(`/sale/contactPerson/createByImport`, params);

/**解析联系人导入文件 */
export const parseUploadedContactPersonTemp = (params) =>
  postRequest(`/sale/contactPerson/parseUploadedContactPersonTemp`, params);

/**下载联系人导入模板 */
export const getContactPersonExcel = () =>
  getRequest(`/sale/contactPerson/getContactPersonExcel`);
