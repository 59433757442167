<template>
  <div class="bgStyle wrapper">
    <div class="head">
      <a-space :size="18">
        <a-input
          placeholder="输入客户名称/联系人/联系方式/所在地信息"
          v-model="customerkey"
          style="width: 320px"
          allowClear
        />
        <a-select
          v-model="enableStatu"
          style="width: 130px"
          ref="select"
          placeholder="选择客户状态"
          allowClear
        >
          <a-select-option
            v-for="item in arrEnableStatus"
            :value="item.id"
            :key="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>

        <a-range-picker
          v-model="time"
          style="width: 210px"
          format="YYYY-MM-DD"
        />

        <a-button type="primary" @click="search">查询</a-button>
      </a-space>
      <a-space :size="24">
        <a-button type="primary" @click="addNew">新增客户</a-button>
        <a-button type="primary" @click="showImportModal">导入客户</a-button>
      </a-space>
    </div>
    <a-table
      :columns="columns"
      :data-source="data"
      :rowKey="(record) => record.id"
      bordered
      :pagination="pagination"
      @change="handleTableChange"
      size="middle"
    >
      <template slot="creationTime" slot-scope="text">
        {{ text | filterDate }}
      </template>
      <!-- <template slot="enableStatu" slot-scope="text,record">
          {{filterType(record.enableStatus)}}
      </template> -->
      <template slot="operation" slot-scope="text, record">
        <a @click="whenEditRow(record)">编辑</a>
        <a-divider type="vertical" />

        <a @click="whenDisableRow(record)">{{
          record.enableStatus == 1 ? "停用" : "启用"
        }}</a>
        <a-divider type="vertical" />
      </template>
    </a-table>

    <CustomerAdd
      ref="add"
      :visible="addCtrl.visible"
      :readonly="addCtrl.readonly"
      :form="this.addCtrl.form"
      :isAdd="this.addCtrl.isAdd"
      :isEdit="this.addCtrl.isEdit"
      :isDetail="this.addCtrl.isDetail"
      @handleTableChange="handleTableChange"
      @getData="getData"
      @submited="
        () => {
          this.addCtrl.visible = false;
        }
      "
      @cancel="
        () => {
          this.addCtrl.visible = false;
        }
      "
    ></CustomerAdd>

    <a-modal
      v-model="visibleImport"
      title="导入客户"
      :width="1200"
      :maskClosable="false"
      :cancel-button-props="{ style: { display: 'none' } }"
      ok-text="开始导入"
      @ok="handleImportOk"
      :confirm-loading="confirmLoading"
    >
      <ImportCustomer
        ref="refImportContactPerson"
        @uploadCustomerSuccess="customerSuccess"
        @changeImportDataAddress="changeImportDataAddress"
      />
    </a-modal>
  </div>
</template>

<script>
import {
  getListCustomer,
  getAllEnableStatusEnum,
  updateCustomer,
  createByImport,
} from "@/api/customer.js";

import CustomerAdd from "./components/AddCustomer.vue";
import ImportCustomer from "./components/ImportCustomer.vue";
const columns = [
  {
    title: "客户名称",
    dataIndex: "name",
    width: 300,
    ellipsis: true,
    align: "center",
  },
  {
    title: "联系人",
    dataIndex: "contactPerson",
    ellipsis: true,
    align: "center",
  },
  {
    title: "联系方式",
    dataIndex: "contactInfo",
    align: "center",
  },
  {
    title: "所在地",
    dataIndex: "address.fullInfoForShow",
    width: 280,
    ellipsis: true,
    align: "center",
  },
  {
    title: "状态",
    dataIndex: "enableStatusStr",
    //scopedSlots:{customRender:"enableStatuStr"}
    width: 100,
    align: "center",
  },
  {
    title: "创建时间",
    dataIndex: "creationTime",
    scopedSlots: { customRender: "creationTime" },
    align: "center",
  },
  {
    title: "操作",
    dataIndex: "operation",
    scopedSlots: { customRender: "operation" },
    width: 150,
    align: "center",
  },
];

export default {
  name: "CustomerManageIndex",
  components: {
    ImportCustomer,
    CustomerAdd,
  },
  data() {
    return {
      customerkey: "",
      enableStatu: undefined,
      province: undefined,
      city: undefined,
      area: undefined,

      time: [],

      data: [],
      columns,

      visibleImport: false,
      confirmLoading: false,
      importData: [],
      pagination: {
        pageSize: 10,
        total: 0,
        current: 1,
        skipCount: 0,
      },
      addCtrl: {
        visible: false,
        readonly: false,
        form: {
          address: {
            provinceId: null,
            cityId: null,
            areaId: null,
            addressDetail: null,
          },
        },
        isAdd: false,
        isDetail: false,
        isEdit: false,
      },
      id: "",
      arrEnableStatus: [],
      objEnableStatu: {},
      provinces: [],
      cities: [],
      areas: [],
    };
  },
  filters: {
    filterDate(val) {
      if (val) {
        let dt = val.substring(0, 19).replace("T", " ");
        return dt;
      } else {
        return "";
      }
    },
  },
  created() {
    this.getEnableStatu();
  },
  methods: {
    /**获取数据 */
    getData() {
      let pageSize = this.pagination.pageSize;
      let skipCount = (this.pagination.current - 1) * pageSize;
      let beginTime =
        this.time[0] && this.time[0].format("YYYY-MM-DD 00:00:00");
      let endTime = this.time[1] && this.time[1].format("YYYY-MM-DD 23:59:59");
      console.log(this.enableStatu);
      let enableStatus = this.enableStatu;
      console.log("asd1234567", enableStatus);

      let params = {
        key: this.customerkey,
        skipCount,
        maxResultCount: pageSize,
        beginTime,
        endTime,
        enableStatus,
      };

      getListCustomer(params).then((res) => {
        console.log("getListCustomer", res);
        this.data = res.items;
        this.pagination.total = res.totalCount;
        this.skipCount = params.skipCount;
      });
    },

    getEnableStatu() {
      getAllEnableStatusEnum().then((res) => {
        this.arrEnableStatus = res || [];
        this.getObjEnableStatu(res);
        this.getData();
        localStorage.setItem(
          "arrEnableStatus",
          JSON.stringify(this.arrEnableStatus)
        );
      });
    },

    getObjEnableStatu(arr) {
      let _this = this;
      arr.forEach((item) => {
        _this.objEnableStatu[item.id] = item.name;
      });
      //console.log("objEnableStatu",this.objEnableStatu);
      localStorage.setItem(
        "objEnableStatu",
        JSON.stringify(this.objEnableStatu)
      );
    },
    /**查找 */
    search() {
      this.pagination.current = 1;
      this.getData();
    },

    // filterType(val) {
    //   console.log("objEnableStatu",this.objEnableStatu[val])
    //   return this.objEnableStatu[val] || "";
    // },

    /**新增 */
    addNew() {
      debugger;
      this.addCtrl.form = {};
      this.addCtrl.form.address = {};
      this.addCtrl.readonly = false;
      this.addCtrl.visible = true;
      this.addCtrl.isAdd = true;
      this.addCtrl.isEdit = false;
      this.addCtrl.isDetail = false;
      console.log("addCtrlinaddnew", this.addCtrl.form);
    },

    /**导入窗口 */
    showImportModal() {
      this.visibleImport = true;
    },

    changeImportDataAddress(provinceId, cityId, areaId) {
      let _this = this;
      _this.importData.forEach((item) => {
        item.address.provinceId = provinceId;
        item.address.cityId = cityId;
        item.address.areaId = areaId;
      });
      console.log("_this.importData", _this.importData);
    },

    /**修改 */
    whenEditRow(row) {
      var currentRow = JSON.parse(JSON.stringify(row));
      console.log("currentRow", currentRow);
      this.addCtrl.form = currentRow;
      this.addCtrl.readonly = false;
      this.addCtrl.visible = true;
      this.addCtrl.isEdit = true;
      this.addCtrl.isAdd = false;
      this.addCtrl.isDetail = false;
    },
    /**停用 */
    whenDisableRow(row) {
      const _this = this;
      this.$confirm({
        title:
          "确认要" +
          (row.enableStatus == 1 ? "停用" : "启用") +
          "客户" +
          row.name +
          "吗?",
        onOk() {
          let customerUpdateInputDto = row;
          row.enableStatus == 1
            ? (customerUpdateInputDto.enableStatus = 2)
            : (customerUpdateInputDto.enableStatus = 1);
          updateCustomer(customerUpdateInputDto)
            .then(() => {
              _this.$message.success("保存成功");
              _this.getData();
            })
            .catch(() => {
              this.$message.error("保存失败");
            });
        },
      });
    },
    /**详情 */
    whenViewRow(row) {
      var entity = JSON.parse(JSON.stringify(row));
      this.addCtrl.form = entity;
      this.addCtrl.readonly = true;
      this.addCtrl.visible = true;
      this.addCtrl.isDetail = true;
      this.addCtrl.isAdd = false;
      this.addCtrl.isEdit = false;
    },

    /**页数更改事件 */
    handleTableChange(pagination) {
      this.pagination.current = pagination.current;
      this.getData();
    },

    /**客户导入事件 */
    handleImportOk() {
      let _this = this;
      console.log("导入窗口中的数据", this.importData);
      var customers = _this.importData;
      createByImport(customers).then(function (res) {
        _this.$message.success("导入成功!");
        _this.visibleImport = false;
        _this.confirmLoading = false;
        _this.getData();
      });
    },
    /**导入文件解析成功 */
    customerSuccess(data) {
      this.importData = data;
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  background: #fff !important;
}

.head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}
</style>
