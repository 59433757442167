<template>
  <div class="importContactPerson">
    <h3>选择文件</h3>
    <a-upload-dragger
      name="file"
      :showUploadList="false"
      :before-upload="beforeUpload"
      :file-list="fileList"
      @change="handleChange"
    >
      <p class="ant-upload-drag-icon">
        <a-icon type="inbox" />
      </p>
      <p class="ant-upload-text">支持拖拽上传或选择文件进行上传</p>
    </a-upload-dragger>

    <a-row class="info-row">
      <a-col :span="6">
        <h3>导入明细</h3>
      </a-col>
      <a-col :span="12">
        <span>请选择客户所在省市区：</span>
        <a-select
          v-model="provinceId"
          style="width: 120px"
          @change="handleProvinceChange(provinceId, '')"
          placeholder="请选择省"
          @focus="onfocus"
        >
          <!-- <a-select-option value="Yiminghe">
          yiminghe
          </a-select-option> -->
          <a-select-option
            v-for="province in provinceData"
            :key="province.no"
            :value="province.id"
          >
            {{ province.regionName }}
          </a-select-option>
        </a-select>
        <a-select
          v-model="cityId"
          style="width: 120px"
          @change="handleCityChange(cityId, '')"
          placeholder="请选择市"
        >
          <a-select-option
            v-for="city in cityData"
            :key="city.no"
            :value="city.id"
          >
            {{ city.regionName }}
          </a-select-option>
        </a-select>
        <a-select
          v-model="areaId"
          style="width: 120px"
          @change="handleAreaChange()"
          placeholder="请选择区"
        >
          <a-select-option
            v-for="area in areaData"
            :key="area.no"
            :value="area.id"
          >
            {{ area.regionName }}
          </a-select-option>
        </a-select>
      </a-col>
      <a-col :span="6" style="text-align: right">
        <a-button type="primary" @click="templateDownload">模板下载</a-button>
        <a-button type="primary" @click="clearClick">清空列表</a-button>
      </a-col>
    </a-row>

    <a-table
      :columns="columns"
      :data-source="importCustomerData"
      :rowKey="(row, index) => index"
      :pagination="pagination"
      @change="handleTableChange"
    >
      <template slot="operation" slot-scope="text, record, index">
        <a-space :size="20">
          <a
            ><a-icon
              type="delete"
              :style="{ fontSize: '26px' }"
              @click="rowClick(index)"
          /></a>
        </a-space>
      </template>
    </a-table>
  </div>
</template>
<script>
const url = process.env.VUE_APP_API_BASE_URL;
import store from "@/store";
import {
  parseImportCustomer,
  getRegionListByParentNo,
} from "@/api/customer.js";

export default {
  data() {
    return {
      columns: [
        {
          title: "客户名称",
          dataIndex: "name",
          ellipsis: true,
          align: "center",
        },
        {
          title: "联系人",
          dataIndex: "contactPerson",
          align: "center",
        },
        {
          title: "联系方式",
          dataIndex: "contactInfo",
          //width: 280,
          ellipsis: true,
          align: "center",
        },
        {
          title: "详细地址",
          dataIndex: "addressDetail",
          //width: 280,
          ellipsis: true,
          align: "center",
        },
      ],
      columnheader: [
        {
          title: "序号",
          dataIndex: "order",
          scopedSlots: { customRender: "no" },
        },
        {
          title: "客户名称",
          dataIndex: "name",
          align: "center",
        },
        {
          title: "联系人",
          dataIndex: "contactPerson",
          align: "center",
        },
        {
          title: "联系方式",
          dataIndex: "contactInfo",
          align: "center",
        },
        {
          title: "详细地址",
          dataIndex: "address.addressDetail",
          align: "center",
        },
      ],
      importCustomerData: [],
      SortDefaultValue: "",
      fileList: [],
      canUpload: false,
      provinceId: undefined,
      cityId: undefined,
      areaId: undefined,
      provinceData: [],
      cityData: [],
      areaData: [],
      pagination: {
        current: 1,
        total: 0,
        pageSize: 10, //每页中显示10条数据
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "50", "100"], //每页中显示的数据
        showTotal: (total) => `共有 ${total} 条数据`, //分页中显示总的数据
      },
    };
  },
  created() {
    //this.getProvinces();
  },
  methods: {
    handleTableChange(pagination) {
      console.log(pagination);
      this.pagination.current = pagination.current; //当前第几页
      this.pagination.pageSize = pagination.pageSize;
      this.SkipCount = (pagination.current - 1) * pagination.pageSize; //从总数据的第几条数据开始取数据
      this.MaxResultCount = pagination.pageSize; //一页多少条
    },
    beforeUpload(file) {
      console.log("file", file);

      this.fileList = [...this.fileList, file];
      if (
        this.provinceId == "" ||
        this.provinceId == undefined ||
        this.cityId == "" ||
        this.cityId == undefined ||
        this.areaId == "" ||
        this.areaId == undefined
      ) {
        this.$message.error("请先选择客户所在省市区!");
        this.canUpload = false;
      } else {
        this.canUpload = true;
      }
      return false;
    },
    handleChange(info) {
      if (this.canUpload) {
        console.log("info", info);
        const formData = new FormData();
        formData.append("file", info.file);
        parseImportCustomer(formData).then((res) => {
          console.log("parseImportCustomer", res);
          console.log("columns", this.columns);
          this.columns.splice.apply(this.columns, [
            0,
            this.columns.length,
            ...this.columnheader,
          ]);
          this.columns.push({
            title: "删除",
            dataIndex: "operation",
            align: "center",
            scopedSlots: { customRender: "operation" },
          });
          console.log(this.columns);
          this.importCustomerData = res.map((item, index) => {
            return Object.assign(item, { order: index + 1 });
          });
          let _this = this;
          if (
            _this.provinceId == "" ||
            _this.cityId == "" ||
            _this.areaId == ""
          ) {
            _this.$message.error("请先选择完地址再进行导入!");
          }
          res.forEach((item) => {
            item.address.provinceId = _this.provinceId;
            item.address.cityId = _this.cityId;
            item.address.areaId = _this.areaId;
          });
          this.$emit("uploadCustomerSuccess", res);
          console.log("res", res);
        });
      }
    },
    rowClick(index) {
      let pageSize = this.pagination.pageSize;
      let current = this.pagination.current - 1;
      let num = current * pageSize + index;
      this.importCustomerData.splice(num, 1);

      this.fileList.splice(num, 1);

      this.pagination.current = 1;
      this.$emit("uploadCustomerSuccess", this.importCustomerData);
    },
    clearClick() {
      console.log("clearClick");
      this.importCustomerData = [];
      this.$emit("uploadCustomerSuccess", this.importCustomerData);
      this.columns.splice.apply(this.columns, [
        0,
        this.columns.length,
        ...this.columnheader,
      ]);
    },
    // 模板下载
    templateDownload() {
      const token = store.getters.getToken;
      const c_c = store.getters.getUserInfo.companyId;
      const __tenant = store.getters.getUserInfo.tenantId;
      const sessionId = store.getters.getSessionId;
      `                  `;
      window.open(
        `${url}/api/sale/customer/getCustomerExcel?` +
          "&access_token=" +
          token +
          "&__tenant=" +
          __tenant +
          "&c_c=" +
          c_c +
          "&c_s=" +
          sessionId +
          "&negotiateVersion=1"
      );
    },

    handleProvinceChange(parentId, parentNo) {
      if (!parentNo) {
        parentNo = this.provinceData.find((aa) => aa.id == parentId).no;
      }
      getRegionListByParentNo(parentNo).then((res) => {
        this.cityData = res;
      });
    },
    handleCityChange(parentId, parentNo) {
      if (!parentNo) {
        parentNo = this.cityData.find((aa) => aa.id == parentId).no;
      }
      getRegionListByParentNo(parentNo).then((res) => {
        this.areaData = res;
      });
    },
    handleAreaChange() {
      //应该通过数据绑定的方式而不是这样赋值
      //this.$parent.addCusForm.address = this.address;
      let _this = this;
      if (_this.fileList.length > 0) {
        this.$emit(
          "changeImportDataAddress",
          _this.provinceId,
          _this.cityId,
          this.areaId
        );
      }
    },
    getProvinces() {
      var _this = this;
      getRegionListByParentNo("0").then((res) => {
        _this.provinceData = res;
        console.log("provinceData", this.provinceData);
      });
    },
    onfocus() {
      this.getProvinces();
    },
  },
};
</script>
<style lang="scss" scoped>
.ant-upload-text span {
  color: #1890ff;
}

.importsort h3 {
  margin-bottom: 20px;
}

.info-row {
  margin: 20px 0;
}

.info-row .ant-btn {
  margin-right: 15px;
}

.info-row .ant-btn:last-child {
  margin-right: 0;
}

.info-row .ant-col-12:nth-child(3) {
  text-align: right;
}
</style>
